import Util from './Util';
import React from 'react';

export default class PaymentSchedule extends React.Component {
  render() {
    const mortgage = this.props.mortgage;
    const { paymentSchedule } = mortgage;
    console.log('const { paymentSchedule } = mortgage;', paymentSchedule);
    const showPennies = false;

    return (
      <div className="tableContainer">
        <table className="paymentTable">
          <thead>
            <tr className="paymentHeader">
              <th>#</th>
              <th>Principal</th>
              <th>Interest</th>
              <th>Total Interest</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            {paymentSchedule.map((payment) => {
              const isYearlyPayment = payment.count % 12 === 0;
              const rowClass = isYearlyPayment
                ? `${'paymentRow'} ${'paymentRowYear'}`
                : 'paymentRow';

              return (
                <tr key={payment.count} className={rowClass}>
                  <td>
                    {!isYearlyPayment
                      ? payment.count
                      : `Year ${payment.count / 12}`}
                  </td>
                  <td>
                    {Util.moneyValue(payment.principalPayment, showPennies)}
                  </td>
                  <td>
                    {Util.moneyValue(payment.interestPayment, showPennies)}
                  </td>
                  <td>{Util.moneyValue(payment.totalInterest, showPennies)}</td>
                  <td>{Util.moneyValue(payment.balance, showPennies)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

import React from 'react';

import mortgageJs from 'mortgage-js'; // Import custom mortgage calculator library
import {
  TextField,
  MenuItem,
  Button,
  Switch,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Typography,
  Grid,
} from '@mui/material'; // Import Material UI components
import { styled } from '@mui/material/styles'; // Import styled utility
import Util from './src-1/Util'; // Utility functions
import PaymentSchedule from './src-1/PaymentSchedule'; // Component for payment schedule

// Define the state interface
interface MortgageCalculatorState {
  totalPrice: number;
  downPayment: number;
  mortgageInsuranceEnabled: boolean;
  additionalPrincipal: number;
  mortgage: {
    loanAmount: number;
    principalAndInterest: number;
    tax: number;
    homeInsurance: number;
    mortgageInsurance: number;
    total: number;
    paymentSchedule: any[];
  };
  showAdvanced?: boolean;
  showPaymentSchedule?: boolean;
}

// Define the props interface
interface MortgageCalculatorProps {
  styles?: any;
  showPaymentSchedule?: boolean;
  price?: any;
  downPayment?: any;
  interestRate?: any;
  months?: any;
  taxRate?: any;
  homeInsuranceRate?: any;
  mortgageInsuranceRate?: any;
  mortgageInsuranceEnabled?: any;
  additionalPrincipalPayment?: any;
  additionalPrincipal?: number | 0;
}

// HOME PRICE FROM URL   example http://localhost:3003/?price=5000000
const urlParams = new URLSearchParams(window.location.search); // Retrieve query parameters
const priceParam = urlParams.get('price'); // Get the 'price' query parameter, if available
let defaultPrice = priceParam
  ? Number(priceParam)
  : Number(localStorage.getItem('homePrice')) || 378000; // Set defaultPrice - If 'price' is a valid number, use it. Otherwise, DEFAULT
localStorage.setItem('homePrice', defaultPrice.toString()); // Cache the home price
console.log('DefaultPrice = ' + defaultPrice);

// RETRIEVE CACHED DOWNPAYMENT FROM LOCAL STORAGE
let cachedDownPayment = localStorage.getItem('downPayment');
let defaultDownPayment = cachedDownPayment ? Number(cachedDownPayment) : 10000; // Set defaultDownPayment to the cached value if available, otherwise use the default value
let defaultInterestRate = Number(localStorage.getItem('interestRate')) || 0.075; // (7.5%)
let defaultDownPaymentPercent =
  Number(localStorage.getItem('downPaymentPercent')) || 0.2; // (20%)
let validTermMonths = [60, 120, 180, 240, 360]; // Valid loan term months
let defaultTermMonths = Number(localStorage.getItem('termMonths')) || 360; // (30 years)
let defaultAdditionalPrincipalPayment =
  Number(localStorage.getItem('additionalPrincipalPayment')) || 0; // Default additional monthly principal payment
console.log(
  'let defaultAdditionalPrincipalPayment ',
  defaultAdditionalPrincipalPayment
);
let defaultTaxRate = Number(localStorage.getItem('taxRate')) || 0.0125; // (1.25%)
let defaultInsuranceRate =
  Number(localStorage.getItem('homeInsuranceRate')) || 0.0014; // (0.14%)
let defaultMortgageInsuranceRate =
  Number(localStorage.getItem('mortgageInsuranceRate')) || 0.011; // (1.1%)
// let durationString =
//   Number(localStorage.getItem('durationString')) || ' Empty lol'; // (1.1%)
// function updateDuration() {}

function updateVariable(name: string, value: any) {
  localStorage.setItem(name, value.toString());
  console.log('name = ', name, ' value = ', value);
}

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: '#21496c',
  '&:hover': {
    backgroundColor: '#63acd4', // Light blue background on hover
    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
  },
}));
const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        backgroundColor: '#63acd4', // Light blue when checked
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#21496c', // Dark Blue thumb color
  },
  '& .MuiSwitch-track': {
    backgroundColor: '#d1d1d1', // Light grey when unchecked
    opacity: 1,
  },
}));

// Main MortgageCalculator component
class MortgageCalculator extends React.Component<
  MortgageCalculatorProps,
  MortgageCalculatorState
> {
  mortgageCalculator = mortgageJs.createMortgageCalculator(); // Initialize mortgage calculator

  constructor(props: MortgageCalculatorProps) {
    super(props);
    // Initialize mortgage calculator with default or provided values
    this.mortgageCalculator.totalPrice = Util.numberValueOrDefault(
      props.price,
      0,
      defaultPrice
    );
    this.mortgageCalculator.downPayment = Util.numberValueOrDefault(
      props.downPayment,
      0,
      defaultDownPayment
    );
    this.mortgageCalculator.interestRate = Util.numberValueOrDefault(
      props.interestRate,
      0,
      defaultInterestRate
    );
    this.mortgageCalculator.months = Util.numberValueInSetOrDefault(
      props.months,
      validTermMonths,
      defaultTermMonths
    );
    this.mortgageCalculator.taxRate = Util.numberValueOrDefault(
      props.taxRate,
      0,
      defaultTaxRate
    );
    this.mortgageCalculator.homeInsuranceRate = Util.numberValueOrDefault(
      props.homeInsuranceRate,
      0,
      defaultInsuranceRate
    );
    this.mortgageCalculator.mortgageInsuranceRate = Util.numberValueOrDefault(
      props.mortgageInsuranceRate,
      0,
      defaultMortgageInsuranceRate
    );
    this.mortgageCalculator.mortgageInsuranceEnabled =
      props.mortgageInsuranceEnabled !== false;

    this.mortgageCalculator.additionalPrincipal = Util.numberValueOrDefault(
      props.additionalPrincipalPayment,
      0,
      defaultAdditionalPrincipalPayment
    );

    // Set initial component state
    this.state = {
      totalPrice: this.mortgageCalculator.totalPrice,
      downPayment: this.mortgageCalculator.downPayment,
      mortgageInsuranceEnabled:
        this.mortgageCalculator.mortgageInsuranceEnabled,
      additionalPrincipal: this.mortgageCalculator.additionalPrincipal,
      mortgage: this.mortgageCalculator.calculatePayment(),
      showAdvanced: true, // Default value for showAdvanced
      showPaymentSchedule: false, // Default value for showAdvanced
    };

    // Bind event handler functions
    this.onPriceChange = this.onPriceChange.bind(this);
    this.onDownPaymentChange = this.onDownPaymentChange.bind(this);
    this.onDownPaymentPercentChange =
      this.onDownPaymentPercentChange.bind(this);
    this.onInterestRateChange = this.onInterestRateChange.bind(this);
    this.onTermMonthsChange = this.onTermMonthsChange.bind(this);
    this.onAdditionalPrincipalChange =
      this.onAdditionalPrincipalChange.bind(this);
    this.onTaxRateChange = this.onTaxRateChange.bind(this);
    this.onInsuranceRateChange = this.onInsuranceRateChange.bind(this);
    this.onMortgageInsuranceRateChange =
      this.onMortgageInsuranceRateChange.bind(this);
    this.onMortgageInsuranceEnabledChange =
      this.onMortgageInsuranceEnabledChange.bind(this);
  }

  // Placeholder for mortgage change event
  onMortgageChange(mortgage: any) {}

  // Event handler for home price change
  onPriceChange(e: { target: { value: any } }) {
    let value = e.target.value;
    if (value.length === 0) {
      this.setState({
        totalPrice: value,
      });
      return;
    }
    value = Util.moneyToValue(value);
    if (isNaN(value)) return;
    this.mortgageCalculator.totalPrice = value;

    let downPaymentPercent =
      this.state.totalPrice > 0
        ? this.state.downPayment / this.state.totalPrice
        : defaultDownPaymentPercent;
    let downPayment = downPaymentPercent * value;
    this.mortgageCalculator.downPayment = downPayment;
    let mortgage = this.mortgageCalculator.calculatePayment();
    this.setState({
      totalPrice: value,
      downPayment: downPayment,
      mortgage: mortgage,
    });
    this.onMortgageChange(mortgage);
    updateVariable('homePrice', value); //Update CACHE
  }

  // Event handler for down payment change
  onDownPaymentChange(e: { target: { value: any } }) {
    let value = e.target.value;
    if (value.length === 0) {
      this.setState({
        downPayment: value,
      });
      return;
    }
    value = Util.moneyToValue(value);
    if (isNaN(value)) return;
    this.mortgageCalculator.downPayment = value;
    let mortgage = this.mortgageCalculator.calculatePayment();
    this.setState({
      downPayment: value,
      mortgage: mortgage,
    });
    this.onMortgageChange(mortgage);
    updateVariable('downPayment', value); //Update CACHE
  }

  // Event handler for down payment percentage change
  onDownPaymentPercentChange(e: { target: { value: any } }) {
    let value = e.target.value;
    if (value.length === 0) {
      this.setState({
        downPayment: value,
      });
      return;
    }
    if (isNaN(value)) return;
    let downPayment = Math.round((value / 100) * this.state.totalPrice);
    this.mortgageCalculator.downPayment = downPayment;
    let mortgage = this.mortgageCalculator.calculatePayment();
    this.setState({
      downPayment: downPayment,
      mortgage: mortgage,
    });
    this.onMortgageChange(mortgage);
    //Update CACHE not needed
  }

  // Event handler for interest rate change
  onInterestRateChange(e: { target: { value: any } }) {
    let value = Util.percentToValue(e.target.value);
    if (isNaN(value)) return;
    this.mortgageCalculator.interestRate = value;
    let mortgage = this.mortgageCalculator.calculatePayment();
    this.setState({
      mortgage: mortgage,
    });
    this.onMortgageChange(mortgage);
    updateVariable('interestRate', value); //Update CACHE
  }

  // Event handler for loan term change
  onTermMonthsChange(e: { target: { value: any } }) {
    let value = e.target.value;
    if (isNaN(value)) return;
    this.mortgageCalculator.months = value;
    let mortgage = this.mortgageCalculator.calculatePayment();
    this.setState({
      mortgage: mortgage,
    });
    this.onMortgageChange(mortgage);
    updateVariable('termMonths', value); //Update CACHE
  }

  // Event handler for additional principal payment change
  onAdditionalPrincipalChange(e: { target: { value: any } }) {
    let value = Util.moneyToValue(e.target.value); // Convert the input value to a number or set to 0 if invalid
    let numericValue = typeof value === 'string' ? parseFloat(value) : value; // Ensure the value is treated as a number
    numericValue = isNaN(numericValue) ? 0 : numericValue; // Fallback to 0 if the value is not a valid number
    this.mortgageCalculator.additionalPrincipalPayment = numericValue; // Update the mortgage calculator with the numeric value
    let mortgage = this.mortgageCalculator.calculatePayment(); // Recalculate the mortgage
    // Update the state with the numeric value and new mortgage
    this.setState({
      additionalPrincipal: numericValue,
      mortgage: mortgage,
    });
    // Trigger mortgage change event
    this.onMortgageChange(mortgage);
    updateVariable('additionalPrincipalPayment', numericValue); //Update CACHE
    // updateVariable('durationString', durationString); //Update CACHE
  }

  // Event handler for tax rate change
  onTaxRateChange(e: { target: { value: any } }) {
    let value = Util.percentToValue(e.target.value);
    if (isNaN(value)) return;
    this.mortgageCalculator.taxRate = value;
    let mortgage = this.mortgageCalculator.calculatePayment();
    this.setState({
      mortgage: mortgage,
    });
    this.onMortgageChange(mortgage);
    updateVariable('taxRate', value); //Update CACHE
  }

  // Event handler for home homeInsurance rate change
  onInsuranceRateChange(e: { target: { value: any } }) {
    let value = Util.percentToValue(e.target.value);
    if (isNaN(value)) return;
    this.mortgageCalculator.homeInsuranceRate = value;
    let mortgage = this.mortgageCalculator.calculatePayment();
    this.setState({
      mortgage: mortgage,
    });
    this.onMortgageChange(mortgage);
    console.log(
      'onInsuranceRateChange',
      this.mortgageCalculator.homeInsuranceRate,
      ' mortgage :',
      mortgage
    );
    updateVariable('homeInsuranceRate', value); //Update CACHE
  }

  // Event handler for MortgageInsuranceRateChange
  onMortgageInsuranceRateChange(e: { target: { value: any } }) {
    let value = Util.percentToValue(e.target.value);
    if (isNaN(value)) return;
    this.mortgageCalculator.mortgageInsuranceRate = value;
    let mortgage = this.mortgageCalculator.calculatePayment();
    this.setState({
      mortgage: mortgage,
    });
    this.onMortgageChange(mortgage);
    updateVariable('mortgageInsuranceRate', value); //Update CACHE
  }

  // Event handler for enabling/disabling MortgageInsurance
  onMortgageInsuranceEnabledChange(e: React.ChangeEvent<HTMLInputElement>) {
    const isEnabled = e.target.checked; // Get the checked state from the event
    this.mortgageCalculator.mortgageInsuranceEnabled = isEnabled;

    // Recalculate the mortgage
    const mortgage = this.mortgageCalculator.calculatePayment();

    // Update the state with the new values
    this.setState({
      mortgageInsuranceEnabled: isEnabled,
      mortgage: mortgage,
    });

    // Trigger the mortgage change event
    this.onMortgageChange(mortgage);
  }

  // Render the mortgage calculator component
  render() {
    const {
      totalPrice,
      downPayment,
      showAdvanced,
      additionalPrincipal,
      showPaymentSchedule,
    } = this.state;
    const {
      loanAmount,
      principalAndInterest,
      tax,
      homeInsurance,
      mortgageInsurance,
      total,
    } = this.state.mortgage;
    const {
      interestRate,
      taxRate,
      homeInsuranceRate,
      mortgageInsuranceRate,
      mortgageInsuranceEnabled,
      months,
    } = this.mortgageCalculator;
    // let paymentCount = this.state.mortgage.paymentSchedule.length;
    // let years = Math.floor(paymentCount / 12);
    // let remainingMonths = paymentCount % 12;
    // let yearsLabel = years === 1 ? 'year' : 'years';
    // let monthsLabel = remainingMonths === 1 ? 'month' : 'months';
    // let separatorLabel = years > 0 && remainingMonths > 0 ? ' and ' : '';
    //////////////
    /////////////
    // let paymentCount = this.state.mortgage.paymentSchedule.length;
    // let years = Math.floor(paymentCount / 12);
    // let remainingMonths = paymentCount % 12;
    // let yearsLabel = years === 1 ? 'year' : 'years';
    // let monthsLabel = remainingMonths === 1 ? 'month' : 'months';
    // let separatorLabel = years > 0 && remainingMonths > 0 ? ' and ' : '';

    // Create the duration string
    // let duration = '';
    // if (years > 0) duration += `${years} ${yearsLabel}`;
    // duration += separatorLabel;
    // if (remainingMonths > 0) duration += `${remainingMonths} ${monthsLabel}`;
    // if (duration.length > 0) duration = `${duration}`;
    // /////////

    // updateVariable('durationString', duration); //Update CACHE

    // if (years > 0) durationString += `${years} ${yearsLabel}`;
    // durationString += separatorLabel;
    // if (remainingMonths > 0)
    //   durationString += `${remainingMonths} ${monthsLabel}`;
    // if (durationString.length > 0) durationString = `${durationString}`;

    ////////
    const downPaymentPercent =
      downPayment === 0 || isNaN(downPayment)
        ? ''
        : totalPrice > 0 && downPayment > 0
        ? downPayment / totalPrice
        : defaultDownPaymentPercent;

    return (
      <div style={{ padding: 20 }}>
        {/* <Typography variant="h4" gutterBottom>
          Tether RE - Mortgage Calculator
        </Typography> */}
        <form noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Home Price"
                type="text"
                name="price"
                InputProps={{ startAdornment: '$' }}
                fullWidth
                value={Util.moneyValue(totalPrice, false, false)}
                onChange={this.onPriceChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Down Payment"
                type="text"
                name="downPayment"
                InputProps={{ startAdornment: '$' }}
                fullWidth
                value={Util.moneyValue(downPayment, false, false)}
                onChange={this.onDownPaymentChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Down Payment (%)"
                type="text"
                name="downPaymentPercent"
                InputProps={{ endAdornment: '%' }}
                fullWidth
                value={Util.percentValue(downPaymentPercent, false)}
                onChange={this.onDownPaymentPercentChange}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Interest Rate (%)"
                type="text"
                name="interestRate"
                InputProps={{ endAdornment: '%' }}
                fullWidth
                value={Util.percentValue(interestRate, false)}
                onChange={this.onInterestRateChange}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel>Loan Term</InputLabel>
                <Select
                  value={months}
                  label="Loan Term"
                  onChange={this.onTermMonthsChange} // Added onChange handler here
                >
                  <MenuItem value={360}>30 years</MenuItem>
                  <MenuItem value={240}>20 years</MenuItem>
                  <MenuItem value={180}>15 years</MenuItem>
                  <MenuItem value={120}>10 years</MenuItem>
                  <MenuItem value={60}>5 years</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Additional Monthly Principal Payment"
                type="text"
                name="additionalPrincipal"
                InputProps={{ startAdornment: '$' }}
                fullWidth
                value={Util.moneyValue(additionalPrincipal, false, false)}
                onChange={this.onAdditionalPrincipalChange}
              />
            </Grid>
            {showAdvanced && (
              <>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Property Tax Yearly Rate"
                    type="text"
                    name="taxRate"
                    InputProps={{ endAdornment: '%' }}
                    fullWidth
                    defaultValue={Util.percentValue(taxRate, false)}
                    onChange={this.onTaxRateChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Home Insurance Yearly Rate"
                    type="text"
                    name="homeInsuranceRate"
                    InputProps={{ endAdornment: '%' }}
                    fullWidth
                    defaultValue={Util.percentValue(homeInsuranceRate, false)}
                    onChange={this.onInsuranceRateChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Private Mortgage Insurance Yearly Rate"
                    type="text"
                    name="mortgageInsuranceRate"
                    InputProps={{ endAdornment: '%' }}
                    fullWidth
                    defaultValue={Util.percentValue(
                      mortgageInsuranceRate,
                      false
                    )}
                    onChange={this.onMortgageInsuranceRateChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <StyledSwitch
                        checked={mortgageInsuranceEnabled}
                        onChange={this.onMortgageInsuranceEnabledChange}
                      />
                    }
                    label="Include Private Mortgage Insurance"
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => this.setState({ showAdvanced: !showAdvanced })}
              >
                {showAdvanced ? 'Hide Advanced' : 'Show Advanced'}
              </StyledButton>
            </Grid>
          </Grid>
        </form>
        <div style={{ marginTop: 20 }}>
          <Typography variant="h6" style={{ paddingBottom: 20 }}>
            Results:
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Loan Amount"
                type="text"
                InputProps={{
                  readOnly: true,
                  style: { backgroundColor: '#f5f5f5' }, // Setting light grey color
                }}
                fullWidth
                value={Util.moneyValue(loanAmount)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Principal & Interest"
                type="text"
                InputProps={{
                  readOnly: true,
                  style: { backgroundColor: '#f5f5f5' }, // Setting light grey color
                }}
                fullWidth
                value={Util.moneyValue(principalAndInterest)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Property Tax (Monthly)"
                type="text"
                InputProps={{
                  readOnly: true,
                  style: { backgroundColor: '#f5f5f5' }, // Setting light grey color
                }}
                fullWidth
                value={Util.moneyValue(tax)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Property Tax (Yearly)"
                type="text"
                InputProps={{
                  readOnly: true,
                  style: { backgroundColor: '#f5f5f5' }, // Setting light grey color
                }}
                fullWidth
                value={Util.moneyValue(tax * 12)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Home Insurance (Monthly)"
                type="text"
                InputProps={{
                  readOnly: true,
                  style: { backgroundColor: '#f5f5f5' }, // Setting light grey color
                }}
                fullWidth
                value={Util.moneyValue(homeInsurance)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Home Insurance (Yearly)"
                type="text"
                InputProps={{
                  readOnly: true,
                  style: { backgroundColor: '#f5f5f5' }, // Setting light grey color
                }}
                fullWidth
                value={Util.moneyValue(homeInsurance * 12)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Private Mortgage Insurance (Monthly)"
                type="text"
                InputProps={{
                  readOnly: true,
                  style: { backgroundColor: '#f5f5f5' }, // Setting light grey color
                }}
                fullWidth
                value={Util.moneyValue(mortgageInsurance)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Private Mortgage Insurance (Yearly)"
                type="text"
                InputProps={{
                  readOnly: true,
                  style: { backgroundColor: '#f5f5f5' }, // Setting light grey color
                }}
                fullWidth
                value={Util.moneyValue(mortgageInsurance * 12)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Total Monthly Payment"
                type="text"
                InputProps={{
                  readOnly: true,
                  style: { backgroundColor: '#f5f5f5' }, // Setting light grey color
                }}
                fullWidth
                value={Util.moneyValue(total)}
              />
            </Grid>
            {/* <Grid item xs={12} sm={12}>
              <TextField
                label="Loan Duration"
                type="text"
                InputProps={{
                  readOnly: true,
                  style: { backgroundColor: '#f5f5f5' }, // Setting light grey color
                }}
                fullWidth
                value={durationString} // Displaying the calculated duration
              />
            </Grid> */}
          </Grid>
        </div>
        <Grid item xs={12}>
          <StyledButton
            variant="contained"
            color="primary"
            className="button"
            onClick={() =>
              this.setState({ showPaymentSchedule: !showPaymentSchedule })
            }
          >
            {showPaymentSchedule
              ? 'Hide Payment Schedule'
              : 'Show Payment Schedule'}
          </StyledButton>
        </Grid>
        {showPaymentSchedule && (
          <div>
            <PaymentSchedule mortgage={this.state.mortgage} />
          </div>
        )}
      </div>
    );
  }
}

export { MortgageCalculator };

///switch the duration change to a simple function that applies the duration change

///////////////////////
///////////////////////
///////////////////////
///////////////////////
